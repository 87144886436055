.content-link-service{
    text-align: center;
    margin-top: 50px;
    a{
        font-family: var(--font-main-regular);
        font-size: 16px;
        text-decoration: underline !important;
        color: var(--dominant-color-dark) !important;
    }
}
.content-options-pu{
    border: 1px solid var(--text-opacity4);
    border-radius: 10px;
    margin: 15px 5% 0;
    display: flex;
    padding: 18px 10px;
    gap: 10px;
    text-decoration: none !important;
    cursor: pointer;
}
.content-title {
    padding-top: 93px;
    padding-left: 5%;
    padding-right: 5%;
}
.customer-service {
    display: flex;
    padding: 30px 5%;
    gap: 10px;
    cursor: pointer;
    a{
        font-size: 16px;
        font-family: var(--font-main-regular);
        color: var(--dominant-color-dark) !important;
    }
}
.form-img{
    top: 130px;
    position: absolute;
    left: 6%;
}
.form-login-failed{
    width: 90%;
    margin: 82px auto 80px auto;
    min-height: 515px;
    box-shadow: 0px 3px 6px var(--text-opacity3);
    background-color: var(--background-color);
    z-index: 1;
}
.link-options-pu {
    font-size: 14px;
    font-family: var(--font-main-semibold);
    color: var(--text-opacity9) !important;
    margin: 0;
    display: flex;
    text-decoration: none;
}
.text-options-user{
    font-size: 14px;
    font-family: var(--font-main-semibold);
    color: var(--text-opacity9) !important;
    display: flex;
    text-decoration: none;
    white-space: nowrap;
}
.text-options-pu {
    font-family: var(--font-main-regular) !important;
    margin: 0;
}
.title-form {
    color: var(--text-opacity9);
    font-family: var(--font-main-semibold);
    font-size: 18px;
    margin: 0;
    display: flex;
}
.title-high {
    color: var(--dominant-color-dark);
    font-family: var(--font-main-semibold);
    font-size: 18px;
    display: flex;
    margin-bottom: 0 !important;
}

@media (max-width:321px) {
    .title-form {
        white-space: nowrap;
    }
}
//Desktop
@media (min-width:1180px) and (max-width: 1400px){
    .content-link-service{
        padding-bottom: 50px;
    }
}
//Giant
@media (min-width:1400px){
    .content-link-service{
        padding-bottom: 50px;
    }
}